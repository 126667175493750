// HOMEPAGE 
@import "./global.scss";
@import "./index.scss";


.grid-x-30-70 {
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 10px;
    gap: 10px;

}

.left-col,
.right-col {
    width: 95%;
}


// CARDS

.card {
    width: 100%;
    padding: 20px;
    background: whitesmoke;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    margin: 30px 0;
    border-radius: 3px;

    &:hover {
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    }

    h1 {
        color: #2c3e50
    }
}

// CARDS - BLANK FOR IMAGES
.card-blank {
    width: 100%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    margin: 30px 0;
    border-radius: 3px;
    overflow: hidden;


    img {
        width: 100%;
        height: auto;
        transition: all .3s ease-in-out;

        &:hover {
            transform: scale(1.2);
            position: relative;
        }
    }

    &:hover {
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    }

    h1 {
        color: #2c3e50
    }
}

// HIGHLIGHT CARDS
.card-highlight {
    width: 100%;
    padding: 20px;
    background: linear-gradient(153deg, rgba(141, 92, 231, 1) 41%, rgba(108, 92, 231, 1) 82%);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    margin: 30px 0;
    border-radius: 3px;
    color: #fff;

    &:hover {
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    }

    h1 {
        color: #2c3e50
    }
}

// BIO CARD
.card-bio {
    width: 100%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    margin: 30px 0;
    border-radius: 3px;
    overflow: hidden;
    background: whitesmoke;


    .card-bio-img {
        overflow: hidden;

        img {
            width: 100%;
            height: 200px;
            transition: all .3s ease-in-out;

            &:hover {
                transform: scale(1.2);
                position: relative;
            }
        }
    }

    .card-bio-img-profile {
        width: 100%;
        display: flex;
        justify-items: center;


        img {
            border-radius: 50%;
            width: 150px;
            height: 150px;
            margin: 0 auto;
            top: -70px;
            position: relative;
            border: 7px solid whitesmoke;
        }
    }

    &:hover {
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    }

    h1 {
        color: #2c3e50
    }
}

// SEPARATOR
.separator {
    margin: 5px auto;
    height: 1px;
    width: 100%;
    background: whitesmoke;
    border-bottom: 1px dashed #ccc;
}

.list-separator {
    width: 100%;
    height: 1px;
    background: #ccc;
    margin: 3px auto;
}

// TITLE
.title {
    font-family: 'Poppins', sans-serif;

    span {
        color: #ccc;
    }
}

@media (max-width: 500px) {
    .grid-x-30-70,
    .left-col,
    .right-col {
        display: grid;
        grid-template-columns: 1fr;
        padding: 10px;
        gap: 10px;
        width: 100%;

    }
    .card,
    .card-bio,
    .card-highlight {
        margin: 10px auto;
    }
    .card-highlight {
        h1 {
            font-size: 1rem;
        }
    }
}