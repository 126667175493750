@import "./index.scss";

// WRAPPER
.global-container {
    width: 100%;
}

.global-wrapper {
    width: 1200px;
    height: auto;
    margin: 0 auto;
}

.global-wrapper-1k {
    width: 1000px;
    margin: 0 auto;
}

.global-wrapper-full {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.grid-wrapper {
    display: grid;
    place-items: center;
    width: 100%;
    margin: 100px auto;
}

.panel-acc {
    color: tomato;
    background: skyblue;
}

// SCROLLTOP COMPONENT
$scrollTopColor: #7158e2;

.rc-scrolltop {
    position: fixed;
    left: 80%;
    border: 5px solid $scrollTopColor;
    border-radius: 50%;

    place-items: center;
    padding: 20px;
    width: 75px;
    height: 75px;
    color: $scrollTopColor;
    transition: all .3s ease;
    font-size: 1rem;
    background: transparent;
    opacity: .3;
    outline: none;

    &:hover {
        color: #fff;
        background: $scrollTopColor;
        width: 100px;
        height: 100px;
        font-size: 2rem;
        cursor: pointer;
        opacity: 1;
    }
}

// MEDIA QUERIES
@media (max-width: 500px) {
    .global-container {
        width: 100vw;
        overflow-y: hidden;
    }

    .global-wrapper-1k {
        width: 100vw;
        overflow-y: hidden;
    }

    .rc-scrolltop {
        display: none;
    }
}