// HOME ROUTING COMPONENT
@import "./index.scss";

// MIXINS
@mixin absolute {
    position: absolute;
}

.my-name-wrapper {
    width: 100%;
    height: auto;
    display: grid;
    place-items: center;
    margin-top: 50px;

    h2 {
        font-family: 'Poppins', sans-serif;
        color: $toggleNav;
        font-weight: 400;
        font-size: 5rem;
    }

    span {
        font-family: 'Poppins', sans-serif;
        color: $navBg;
        font-weight: 600;
        font-size: 5rem;
    }

    .greetings {
        font-size: 3rem;
        color: #ccc;
        font-family: 'Poppins', sans-serif;
        margin: 0 5px;
    }



}
.grid-wrapper {
    margin: 0 auto;
}

// GO DOWN BUTTON

.goDownBtn {
    margin: 50px auto;
    background: none;
    width: 70px;
    height: 70px;
    font-size: 1.6rem;
    color: #ccc;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 50%;
    animation: testMe 3s ease infinite;

    &:hover {
        background: $navBg;
        color: whitesmoke;
        font-size: 1.8rem;
        cursor: pointer;
    }


}

// SVG's

.svg-mixer,
.svg-bbq,
.svg-apron,
.svg-pot,
.svg-plate {
    @include absolute;
}

.svg-mixer {
    top: 70%;
    left: 80%;
}

.svg-apron {
    top: 30%;
    left: 50%;
}

.svg-bbq {
    top: 70%;
    left: 10%;
    transform: rotateX(25deg)
}

.svg-pot {
    top: 50%;
    left: 60%;
}

.svg-plate {
    top: 30%;
    left: 65%;
    transition: transform .7s ease-in-out;
}

// MEDIA QUERIES
@media (max-width: 500px) {
    .my-name-wrapper {
        width: 100%;
        margin-top: 150px;
        h2 {
            font-size: 1.8rem;
            margin-left: 2em;
        }
        span {
            font-size: 1.8rem;
        }
    }
    .svg-plate,
    .svg-apron,
    .svg-bbq,
    .svg-mixer {
        display: none;
    }
}