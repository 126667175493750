// DOWNLOAD COMPONENT

@import "../global.scss";
@import "../index.scss";
@import "../homepage.scss";


.download {
    display: grid;
    place-items: center;

    h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        font-weight: 500;
    }

    button {
        width: 80%;
        font-size: 1.2rem;
        color: whitesmoke;
        padding: 10px;
        background: $navBg;
        border: none;
        margin-top: 10px;
        font-family: 'Poppins', sans-serif;
        transition: all .2s ease-in-out;
        
        &:hover {
            background: #0fb9b1;
            cursor: pointer;
        }
    }
}
@media (max-width: 500px) {
    .download {
        h3 {
            font-size: 0.9rem;
        }
    }
}