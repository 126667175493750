// MODAL
@import "../index.scss";
@import "../global.scss";

.global-modal {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: grid;
    place-items: center;

}

.global-modal-inner {
    border-radius: 5px;
    width: 800px;
    height: 550px;
    padding: 50px;
    background: whitesmoke;
    box-shadow: 3px 33px 33px 3px rgba(0, 0, 0, 0.1);
    display: grid;
    place-items: center;
    // height: 200px;
}

.global-modal-content {
    display: grid;
    place-items: center;
    height: 250px;

    h1 {
        font-size: 3rem;
        margin: 0 auto;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    h2 {
        font-size: 2rem;
        margin: 0 auto;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 50px;
    }

    p,
    label {
        font-size: .8rem;
        margin: 0 auto;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    span {
        color: #7158e2
    }

}

.global-modal-buttons {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 20px auto auto auto;
    place-items: center;
    gap: 20px;

}



// BUTTONS
.modal-enter {

    padding: 10px;
    color: #2c3e50;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    border: 1px solid #ccc;
    transition: all .2s ease;

    &:disabled:hover {
        color: white;
        background: #c0392b;
        border: 1px solid #ccc;
        cursor: pointer;
    }

    &:hover {
        color: whitesmoke;
        background: #27ae60;
        border: 1px solid #ccc;
        cursor: pointer;
    }

}


.modal-leave {}