// JOBS COMPONENT
$summary:#6c5ce7;

.jobs {
    display: grid;
    grid-template-columns: 25% 75%;
    gap: 10px;

    h2 {
        color: #2c3e50;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    img {
        width: 100%;
        height: auto;
    }

    p {
        color: #2c3e50;
        font-family: 'Poppins', sans-serif;
        font-size: .8rem;
    }

    .jobs-border {
        border-left: 1px dashed #ccc;
        padding: 10px;
    }
}

.jobs-info {

    p {
        color: #2c3e50;
        font-family: 'Poppins', sans-serif;
        font-size: .8rem;
        margin: 0 5px;
    }
}

// SUMMARY
summary {
    color: $summary;
    font-family: 'Poppins', sans-serif;
    font-size: .8rem;
    padding: 10px;
    border-top: 1px dashed #ccc;
    margin-top: 10px;
}

summary:hover {
    cursor: pointer;
}

summary::marker {
    color: $summary;
}

summary:focus {
    outline: none;
}

// SUMMARY PICTURES
.summary-pictures {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin: 20px 0;

    img {
        width: 100%;
        height: auto;
    }
}

.jobs-separator {
    width: 100%;
    height: 1px;
    background: whitesmoke;
    border-bottom: 1px dashed #ccc;
    margin: 20px auto;
}

@media (max-width: 500px) {
    .jobs {
        display: grid;
        grid-template-columns: 1fr;

        .jobs-border {
            border-left: none;
        }

        div {
            display: grid;
           

            img {
                width: 70%;
                margin: 0 auto;

            }
        }
    }
}