// MIXINS
$bodyBg: #222f3e;
$redish: #e20001;
$navBg: #6c5ce7;
$blackish: #2c3e50;
$mainLight: #ddd;
$socialNav: whitesmoke;
$toggleNav: #2c3e50;
$toggleNavHover: #2980b9;
$paragraphColor: #f5f6fa;
// RESET 

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0
}

body {
    background: $mainLight
}

// SITE NAME
.site-name {
    h2 {
        font-family: 'Poppins', sans-serif;
        color: $toggleNav;
        font-weight: 200;
    }

    span {
        font-family: 'Poppins', sans-serif;
        color: $navBg;
        font-weight: 400;
    }
}

// NAVBAR 
header {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    border-bottom: 1px solid #ccc;
    background: whitesmoke;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.07);
}

nav {
    width: 1000px;
    margin: 0 auto;
    height: auto;
    padding: 20px;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;


    div {
        display: grid;
    }

    li {
        display: inline-block;
        margin: 0 5px;
        padding: 0;
    }
}




li {
    list-style: none;
}

.nav-links {
    font-size: 1.1rem;
    font-weight: 300;
    list-style: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    color: $blackish;
    transition: color .3s ease;

    &:hover {
        color: $navBg;
        cursor: pointer;
    }
}


// FONT AWESOME

.fa-bars {
    color: $toggleNav;
    font-size: 1.4rem;

    &:hover {
        color: $navBg;
        cursor: pointer;
    }
}

.fa-nav {
    font-size: 2rem;
    color: $socialNav;

}

// MENU SHOW ON/OFF BUTTON
.menuBtn {
    background: none;
    border: none;
    padding: 5px;
}

.closeBtn {
    left: 90%;
    top: 10%;
    position: absolute;
}


@media (max-width: 500px) {
    header {
        width: 100vw;
    }

    nav {
        width: 100vw;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
    }

    .site-name {
        border-bottom: 1px dashed #ccc;
    }

    .nav-links {

        font-size: 1.2rem;
        font-weight: 500;
        list-style: none;
        text-decoration: none;
        font-family: 'Poppins', sans-serif;
        color: $blackish;
    }

}