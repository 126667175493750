// BIO COMPONENT

@import "../global.scss";
@import "../index.scss";
@import "../homepage.scss";

.bio-description {
    padding: 20px;


    h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    span {
        color: $navBg;
    }

    li,
    p {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    ul {
        margin-top: 20px;
    }

    li {
        font-size: .8rem;
        margin: 5px 0;
        span {
            width: 20px;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

.aws-bio {
    color: #ccc;
    font-size: 1rem;
}
@media (max-width: 500px) {
    .bio-description {
        padding: 10px;

        h1 {
            font-size: 1rem;
        }
    }
}