// EDUCATION AND TRAININGS COMPONENTS

@import "../global.scss";
@import "../index.scss";
@import "../homepage.scss";

.edu-wrapper {
    display: grid;
    grid-template-columns: 20% 80%;

    .edu-image {
        display: grid;
        place-items: center;
    }

    h2 {
        color: #2c3e50;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    p {
        color: #2c3e50;
        font-family: 'Poppins', sans-serif;
        font-size: .8rem;
        margin: 5px;
    }

    .para-cutout {
        color: #ccc;
    }

    .edu-border {
        border-left: 1px dashed #ccc;
        padding: 10px;

        p {
            color: rgb(146, 145, 145);
        }
    }
}

.btn-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}


.btn-trainings {
    padding: 10px;
    color: #2c3e50;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    border: 1px solid #ccc;
    transition: all .2s ease;

    &:hover {
        color: whitesmoke;
        background: $navBg;
        border: 1px solid #ccc;
        cursor: pointer;
    }
}

@media (max-width: 500px) {

    .edu-wrapper,
    .btn-group {
        display: grid;
        grid-template-columns: 1fr;
    }

    .edu-wrapper {
        .edu-border {
            border-left: 1px solid whitesmoke;
        }
    }
    button {
        width: 100%;
    }


}