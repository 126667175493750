// CONTACT ROUTING COMPONENT
@import "./index.scss";

.contact-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 100px;
}

.contact-text {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;

    h1,
    h2,
    h3,
    p {
        font-family: 'Poppins', sans-serif;
    }

    h1 {
        font-weight: 700;
        color: $navBg
    }

    h2 {
        color: $blackish;
        font-weight: 400;
        margin: 30px 0;
    }

    h3 {
        color: $blackish;
        font-weight: 400;
        margin: 20px 0;
    }

    span {
        color: $navBg;
        text-decoration: underline;
    }
}

.contact-text-details {
    padding: 50px;
}

.aws-contact {
    font-size: 3rem;
    margin: 20px 10px;
    color: #535c68;

    &:hover {
        color: $navBg;
        cursor: pointer;
    }
}

form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    background: whitesmoke;
    padding: 20px;
    border-radius: 5px;

    label,
    ::placeholder {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    label {
        color: $blackish;
    }

    ::placeholder {
        color: #ccc;
    }

    input,
    textarea {
        padding: 10px;
        font-family: 'Poppins', sans-serif;
        border: 1px solid #ccc;
        box-shadow: 0px 50px 50px 3px rgba(0, 0, 0, 0.04);

        &:focus {
            border: 1px solid $navBg;
            outline: none;
        }

    }

    input[type=submit] {
        background: $navBg;
        color: white;
    }
}

// MEDIA QUERIES
@media (max-width: 500px) {
    .contact-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        padding: 20px;
        margin-top: 20px;
    }

    .aws-contact {
        font-size: 2rem;
    }
}