// GALLERY ROUTING COMPONENT

@import "./global.scss";
@import "./homepage.scss";
@import "./index.scss";


div.gallery-grid {
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin: 50px auto;

    a {
        padding: 0;
        margin: 0;
    }
}

.gallery-box {
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
    padding: 0;
    opacity: 0;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 0px 40px 70px 0px rgba(0, 0, 0, 0.2);
    }


}

.gallery-img-thumb {

    width: 300px;
    height: 200px;
    filter: grayscale(.9);
    transition: all .2s ease;

    &:hover {
        filter: grayscale(0);
        transform: scale(1.1);
        position: relative;
    }
}

.gallery-button-group {
    width: 75%;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.gallery-button {
    padding: 10px;
    color: #2c3e50;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    border: 1px solid #ccc;
    transition: all .2s ease;

    &:hover {
        color: whitesmoke;
        background: $navBg;
        border: 1px solid #ccc;
        cursor: pointer;
    }
}

.btn-active {
    color: whitesmoke;
    background: $navBg;
    border: 1px solid #ccc;
}

@media (max-width: 500px) {
    div.gallery-grid {
        width: 100%;
        display: grid;
        place-items: center;
        grid-template-columns: 1fr;
        gap: 10px;
        margin: 20px auto;

        a {
            padding: 0;
            margin: 0;
        }
    }

    .gallery-button-group {
        width: 100%;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
    }

    .gallery-box {
        width: 100%;
        box-shadow: none;

        &:hover {
            box-shadow: none
        }

        &:last-child {
            margin-bottom: 50px;
        }
    }

    .gallery-img-thumb {
        width: 320px;
        filter: grayscale(0);

        &:hover {
            transform: scale(1)
        }

    }
}