// HOBBY COMPONENT
@import "../global.scss";
@import "../index.scss";
@import "../homepage.scss";

.hobby-button {
    padding: 10px;
    color: #2c3e50;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    border: 1px solid #ccc;
    transition: all .2s ease;

    &:hover {
        color: whitesmoke;
        background: $navBg;
        border: 1px solid #ccc;
        cursor: pointer;
    }
}

.hobby-buttons-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.hobby-container {

    li,
    p {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    ul {
        margin-top: 20px;
    }

    li {
        font-size: 1rem;
        margin: 7px 0;

            span {
            width: 20px;
            display: inline-block;
            margin-right: 5px;
        }
    }
}


// FONT AWESOME

.aws-hobby {
    color: #ccc;
    font-size: 1rem;
}

.hobby-active {
    color: whitesmoke;
    background: $navBg
}